<template>
  <div v-if="loading" style="margin-top: 36px;">
    <div class="loader"></div>
  </div>
  <div v-else>
    <div v-if="successfulResponse" class="margin">
      {{ `You (${decodeURIComponent(recipient)}) have been blocked from having ${frequency} screenshot emails sent from the following account: ${decodeURIComponent(accountId)}`}}
    </div>
    <div v-else>
      <span>Issue blocking your email on that account, please try again later.</span>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'about',
    data () {
      return {
        loading: true,
        recipient: null,
        accountId: null,
        successfulResponse: false
      }
    },
    methods: {
      loadAndValidateQueryParameters() {
        this.recipient = this.$route.query.recipient;
        this.accountId = this.$route.query.account;
        this.frequency = this.$route.query.frequency

      },
      async block() {

        let confirmed = confirm(`Are you sure you would like to block your email address from receiving emails for ${decodeURIComponent(this.$route.query.account)}'s account?  To undo this you'll need to email support@truple.io.`)
        if(!confirmed) {
          this.successfulResponse = false
          return;
        }

        try {
          await this.api.dailyEmailUnsubscribe({
            recipient: this.$route.query.recipient,
            accountId: this.$route.query.account,
            frequency: this.$route.query.frequency,
            block: true
          })
          this.successfulResponse = true
        } catch(err) {
          // eslint-disable-next-line no-console
          console.log(err)
          alert('Error blocking.  Please try again.  If it persists, contact support@truple.io.')
        } finally {
          this.loading = false
        }
      }
    },
    beforeMount() {
      this.api.setUnauthedCredentials();
      this.loadAndValidateQueryParameters();
      this.block();
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .margin {
    margin: 32px 32px 32px 32px;
  }
</style>
